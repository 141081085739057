<template>
  <b-modal
    size="lg"
    id="bulkZuweisenModal"
    :title="selectedAufgabenCount + ' Aufgaben neu zuweisen'"
    centered
    @hide="onHide"
  >
    <div>
      <div class="d-flex" style="gap: 8px">
        <b-form-group class="w-50" label="Bearbeiter/in" label-for="bearbeiter">
          <UsersMultiselect
            v-model="bearbeiter"
            :options="zugewiesenePersonOptions"
            class="mr-2 border"
            :disabled="team != null"
            :backgroundColor="team != null ? '#ededed' : ''"
            style="font-size: 14px; height: 36px; flex-grow: 1; border-radius: 8px"
            :placeholder="'Wähle eine/n Bearbeiter/in...'"
          ></UsersMultiselect>
        </b-form-group>

        <b-button
          @click="clickIch"
          size="sm"
          style="height: 38px"
          class="mt-8"
          :variant="bearbeiter === currentUserId ? 'secondary' : 'primary'"
          :disabled="bearbeiter === currentUserId"
        >
          Ich
        </b-button>

        <b-form-group class="w-50" label="Team" label-for="team">
          <b-form-select
            id="team"
            v-model="team"
            :options="teamOptions"
            :disabled="bearbeiter != null"
            :class="team === null && 'placeholder-multiselect'"
          >
            <template #first>
              <b-form-select-option
                v-if="bearbeiter == null"
                :value="null"
                disabled
                class="placeholder-multiselect"
              >
                oder ein Team.
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <i
          v-if="bearbeiter != null || team != null"
          class="fas fa-times text-danger align-self-center ml-2"
          @click="resetPersonOderTeam"
          style="cursor: pointer"
        ></i>
      </div>
    </div>
    <template #modal-footer="{ hide }">
      <b-button
        @click="neuZuweisen(hide)"
        class="mr-4"
        :disabled="bearbeiter == null && team == null"
        :variant="bearbeiter != null || team != null ? 'primary' : ''"
      >
        Sichern
      </b-button>
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>
<script>
import apiService from '@/core/common/services/api.service';
import { mapState, mapGetters } from 'vuex';
import { changePeopleToCorrectShape, permittedDepartments } from '@/core/common/helpers/utils.js';
import { GET_AUFGABEN_NOTIFICATION_COUNT } from '@/core/aufgaben/stores/aufgaben.module';
import UsersMultiselect from '@/components/common/users-multiselect.vue';

export default {
  props: { selectedAufgabenCount: { type: Number }, selectedAufgabenIds: { type: Array, required: true } },
  components: { UsersMultiselect },
  data() {
    return {
      bearbeiter: null,
      team: null,
    };
  },
  computed: {
    ...mapState({
      users: state => state.users['users'],
    }),
    ...mapGetters(['parsedRoles', 'currentUserId']),

    zugewiesenePersonOptions() {
      if (this.isAdmin) {
        return changePeopleToCorrectShape(this.users);
      } else {
        return changePeopleToCorrectShape(this.peopleWithRestrictedPermission) || [];
      }
    },
    isAdmin() {
      return this.parsedRoles.includes('Admin');
    },
    peopleWithRestrictedPermission() {
      return this.users?.filter(
        user => permittedDepartments.includes(user.department) || user.id === this.userIdWebstada
      );
    },
    teamOptions() {
      if (this.isAdmin) {
        return this.changeTeamsToCorrectShape(this.users);
      } else {
        return this.changeTeamsToCorrectShape(this.peopleWithRestrictedPermission) || [];
      }
    },
  },
  methods: {
    async neuZuweisen(hide) {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        'Willst du die ausgewählten Aufgaben wirklich neu zuweisen?',
        {
          centered: true,
          okTitle: 'OK',
          cancelTitle: 'Abbrechen',
        }
      );
      if (confirmed) {
        apiService
          .put('/aufgaben/bulk', {
            ids: this.selectedAufgabenIds,
            bearbeiter: this.bearbeiter,
            team: this.team,
          })
          .then(() => {
            this.toast('Erfolgreich zugewiesen.');
            hide();
            this.$emit('neuZugewiesen');
            this.$store.dispatch(GET_AUFGABEN_NOTIFICATION_COUNT);
          });
      }
    },
    onHide() {
      this.bearbeiter = null;
      this.team = null;
    },
    changeTeamsToCorrectShape(data) {
      const duplicateDepartments = data
        .filter(({ givenName }) => givenName !== null && !givenName.toLowerCase().includes('test'))
        .filter(({ surname }) => surname !== null && !surname.toLowerCase().includes('test'))
        .filter(({ mail }) => mail !== null)
        .map(({ department }) => department);
      return [...new Set(duplicateDepartments)]
        .filter(dep => dep !== null)
        .map(department => ({ value: department, text: department }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    clickIch() {
      this.bearbeiter = this.currentUserId;
      this.team = null;
    },
    resetPersonOderTeam() {
      this.bearbeiter = null;
      this.team = null;
    },
  },
};
</script>
<style scoped>
.placeholder-multiselect {
  color: #b5b5c3;
}
</style>
