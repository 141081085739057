<template>
  <div v-if="!params.data.team" class="py-1 px-2 rounded border" v-bind:style="{ backgroundColor: color }">
    <span>{{ this.params.value.value ?? 'Automatisch erstellt' }}</span>
  </div>
  <div v-else></div>
</template>
<script>
export default {
  data() {
    return {
      name: null,
      color: null,
    };
  },
  beforeMount() {
    this.data = this.params.api.getDisplayedRowAtIndex(this.params.rowIndex).data;
    this.color = this.params.color || 'white';
  },
};
</script>
